import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CardRow, TypeTitle, TypeDescription } from "./Card"
import Flavor from "./Flavor"

import { chocolatePeanutButter, saltedCaramel } from "../../data/proteinDataFr"

export default function FrenchProtein(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsProteinFr {
      chocolatePeanutButterFr: file(
        relativePath: { eq: "front-page-flavors/protein-pb-chocolate-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      saltedCaramelFr: file(
        relativePath: {
          eq: "front-page-flavors/protein-salted-caramel-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Protéine</TypeTitle>
      <TypeDescription>
        Avec 15g de protéines, nos boissons protéinées sont idéales pour une
        satisfaction en déplacement.
      </TypeDescription>

      <CardRow>
        <Flavor
          data={chocolatePeanutButter}
          fixed={images.chocolatePeanutButterFr.childImageSharp.fixed}
          flavorIndex={9}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={saltedCaramel}
          fixed={images.saltedCaramelFr.childImageSharp.fixed}
          flavorIndex={10}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
