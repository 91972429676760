import React from "react"

import Card from "./Card"
import Detail from "./Detail/"

export default function Flavor(props) {
  return (
    <>
      <Card {...props} />

      <Detail {...props} />
    </>
  )
}
