export const chocolatePeanutButter = {
  id: "proteinChocolatePeanutButter",
  backgroundColor: "#C65100",
  textColor: "#FFFFFF",
  name: "Beurre d’arachide au chocolat boisson protéinée",
  tagline: "Beurre d’arachide au chocolat boisson protéinée",
  description:
    "Améliorez votre jeu avec ce combo classique! Avec 18 g de protéines végétales, celui-ci est sûr de vous satisfaire.",
  ingredients:
    "Eau, Sucre, Crème de noix de coco, Isolat de protéine de pois, Arachides grillées à sec, Cacao, Cacao traité avec un alcali, Gomme arabique, Arômes naturels, Sel, Lécithine de tournesol, Phosphate tripotassique, Gomme gellane, Carboxyméthyl cellulose, Pectine.",
  disclaimer:
    "Contient: Noix de coco, Arachides. Peut contenir: Lait, Soja, Noix, Blé, Œufs provenant de la frabrication et du mélange.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "310",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "12g",
        percent: "15%",
      },
      {
        type: "secondary",
        label: "Saturés",
        value: "6g",
        percent: "30%",
      },
      {
        type: "secondary",
        label: "+ Trans",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "35g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "3g",
        percent: "11%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "29g",
        percent: "29%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "18g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "410mg",
        percent: "18%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "550mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "100mg",
        percent: "8%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "2.5mg",
        percent: "14%",
      },
    ],
  },
}

export const saltedCaramel = {
  id: "proteinSaltedCaramel",
  backgroundColor: "#A26528",
  textColor: "#FFFFFF",
  name: "Caramel Sale Boisson Protéinée",
  tagline: "Caramel Sale Boisson Protéinée",
  description: false,
  ingredients:
    "Water, Coconut cream, Pea protein isolate, Sugar, Maltodextrin, Caramel, Natural flavours, Gum arabic, Salt, Carboxymethyl cellulose, Sunflower lecithin, Tripotassium phosphate, Gellan gum, Pectin.",
  disclaimer:
    "Contains: Coconut. May contain: Milk, Soy, Peanuts, Tree nuts, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Par 1 Contenant (237 mL/355 mL une fois préparé)",
    calories: "270",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "7g",
        percent: "9%",
      },
      {
        type: "secondary",
        label: "Saturés",
        value: "5g",
        percent: "25%",
      },
      {
        type: "secondary",
        label: "+ Trans",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "38g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "25g",
        percent: "25%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "15g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "540mg",
        percent: "23%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "350mg",
        percent: "7%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "100mg",
        percent: "8%",
      },
      {
        type: "primary",
        label: "Fer",
        value: "0.3mg",
        percent: "2%",
      },
    ],
  },
}
